import React, { useEffect, useState, lazy, Suspense } from "react";
import { PageLayout } from "./components/Nav";
import { PrimeReactProvider } from "primereact/api";
import { Help } from "./components/Help";
import { isEqual } from 'lodash'
// import { RenderCounter } from "./pages/apps/NCTO/UtilityComponets";

import "./style/primereact-theme.css";
import "./style/App.css";
import "./style/theme.css";
import "/node_modules/primeflex/primeflex.css";
import "/node_modules/bootstrap/dist/css/bootstrap.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "./utils/redux/loginSlice";
import { callMsGraph } from "./graph";

import ClipLoader from "react-spinners/ClipLoader";
import RingLoader from "react-spinners/RingLoader";

import {
    DataRefresher,
    db,
    GetRemoteVersions,
    //   UpdateLessChangelog,
} from "./utils/DataRefresher";

import { loginRequest } from "./authConfig";
import { MyResponsibleWIP } from "./pages/reports/wip/WIPreports";
// import { MyWIP } from "./pages/reports/wip/MyWIP";
import NCTO from "./pages/apps/NCTO/NCTO";
// const Reports = lazy(() => import("./pages/reports/Reports"));
const Home = lazy(() => import("./pages/Home"));
// import Reports from "./pages/reports/Reports";
// const FirmAR = lazy(() => import("./pages/reports/ar/FirmAR"));
// const DeptAR = lazy(() => import("./pages/reports/ar/DeptAR"));
// const RespAR = lazy(() => import("./pages/reports/ar/RespAR"));
// const Member = lazy(() => import("./pages/reports/powerbi/Member"));
const Morale = lazy(() => import("./pages/people/Morale/Morale"));
const Review = lazy(() => import("./pages/people/Review/Review"));
const Request = lazy(() => import("./pages/people/Request/Request"))
// const NCTO = lazy(() => import("./pages/apps/NCTO/NCTO"))
const NCTOLandingPage = lazy(() => import("./pages/apps/NCTO/LandingPage"));
const Approval = lazy(() => import("./pages/apps/NCTO/Approval"));
const ApprovalListPage = lazy(() =>
    import("./pages/apps/NCTO/ApprovalListPage")
);
const BOI = lazy(() => import("./pages/apps/NCTO/BOI"));
const Reject = lazy(() => import('./pages/apps/NCTO/Reject'))

const TeamReviews = lazy(() => import('./pages/reports/hr/TeamReviews'))
const FirmReviews = lazy(() => import('./pages/reports/hr/FirmReviews'))
const FirmMorale = lazy(() => import('./pages/reports/hr/FirmMorale'))
const TeamMorale = lazy(() => import('./pages/reports/hr/TeamMorale'))
const FirmRequests = lazy(() => import('./pages/reports/hr/FirmRequests'))
const FirmTime = lazy(() => import('./pages/reports/time/FirmTime'))
const SelfTime = lazy(() => import('./pages/reports/time/SelfTime'))

// const apiRoot = "https://magnify-apis.azurewebsites.net";
// const apiRoot = "http://localhost:7071";

const Loading = (props) => {
    let loadingScreen;

    switch (props.loader) {
        case "ring":
            loadingScreen = <RingLoader />;
            break;
        case "clip":
            loadingScreen = <ClipLoader />;
            break;
        default:
            loadingScreen = (
                <RingLoader
                    color="Black"
                    loading={props.dataRefreshingState}
                    cssOverride={props.override}
                    size={50}
                    aria-label="Refreshing Data"
                    data-testid="data-refresh-loader"
                />
            );
    }
    return (
        <section
            style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
            }}
        >
            {loadingScreen}
        </section>
    );
};

function ActiveUserRoute(props) {
    const version = "2.0.3"

    try {
        if (localStorage.getItem('version') !== version) {
            localStorage.clear()
            sessionStorage.clear()

            localStorage.setItem('version', version)
        }
    } catch {
        localStorage.clear()
        sessionStorage.clear()

        localStorage.setItem('version', version)
    }

    const authenticated = useSelector((state) => state.user.authenticated);
    const user = useSelector((state) => state.user.STAFFINDEX);
    const bearer = useSelector((state) => state.user.bearer);
    const apiRoot = useSelector(state => state.globals.apiRoot)

    if (authenticated === true) {
        // if (true === true) {
        fetch(`${apiRoot}/api/tracking/login/${user}`, {
            method: "PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: bearer,
            },
        })

        return (
            <Router>
                <PageLayout setShowHelp={props.setShowHelp}>
                    <Suspense fallback={<br></br> /*<Loading loader='ring' />*/}>
                        <Routes>
                            <Route
                                exact
                                path="/"
                                element={
                                    <Home
                                        setGraph={props.setGraph}
                                        inProgress={props.inProgress}
                                    />
                                }
                            />
                            {/* <Route exact path="/reports" element={<Reports />} /> */}
                            {/* <Route exact path="/reports/ar/firm" element={<FirmAR />} /> */}
                            {/* <Route exact path="/reports/ar/dept" element={<DeptAR />} /> */}
                            {/* <Route
                exact
                path="/reports/ar/responsible"
                element={<RespAR />}
              /> */}

                            <Route
                                exact
                                path="/reports/wip/responsible"
                                element={<MyResponsibleWIP />}
                            />
                            <Route
                                exact
                                path='/reports/reviews/team'
                                element={<TeamReviews />}
                            />
                            <Route
                                exact
                                path='/reports/reviews/firm'
                                element={<FirmReviews />}
                            />
                            <Route
                                exact
                                path='/reports/morale/firm'
                                element={<FirmMorale />}
                            />
                            <Route
                                exact
                                path='/reports/morale/team'
                                element={<TeamMorale />}
                            />
                            <Route
                                exact
                                path='/reports/requests/firm'
                                element={<FirmRequests />}
                            />
                            <Route
                                exact
                                path='/reports/time/firm'
                                element={<FirmTime />}
                            />
                            <Route
                                exact
                                path='/reports/time/self'
                                element={<SelfTime />}
                            />
                            {/* <Route exact path="/reports/wip/self" element={<MyWIP />} /> */}
                            {/* <Route exact path="/reports/wip/dept" element={<DeptWIP />} /> */}
                            {/* <Route exact path="/reports/wip/team" element={<TeamWIP />} /> */}

                            {/* <Route
                exact
                path="/reports/powerbi/member"
                element={<Member />}
              /> */}
                            <Route exact path="/people/morale" element={<Morale />} />
                            <Route exact path="/people/reviews" element={<Review />} />
                            <Route exact path="/people/requests" element={<Request />} />
                            {/* <Route exact path="/apps/bingo" element={<Bingo />} />  */}

                            <Route exact path="/apps/ncto" element={<NCTOLandingPage />} />
                            <Route exact path="/apps/ncto/form" element={<NCTO />} />
                            <Route
                                exact
                                path="/apps/ncto/approve/:office"
                                element={<ApprovalListPage />}
                            />
                            <Route
                                path="/apps/ncto/approve/:office/:key"
                                element={<Approval />}
                            />
                            <Route
                                path='/apps/ncto/reject/:office/:key'
                                element={<Reject />}
                            />
                            <Route exact path="/apps/ncto/boi" element={<BOI />} />

                            {/* <Route exact path='/reports/powerbi/hr' Component={} /> */}
                        </Routes>
                    </Suspense>
                </PageLayout>
            </Router>
        );
    } else if (authenticated === false) {
        return (
            <section>
                <p>Uh oh! Looks like you are not a registered user with this company</p>
                <button onClick={() => {
                    localStorage.clear()
                    sessionStorage.clear()
                    window.location.reload()
                }}>Click here to reset and login again</button>
            </section>
        );
    } else {
        return <ClipLoader loading={true} />;
    }
}

export default function App() {
    const [graphData, setGraphData] = useState(null);
    const [showHelp, setShowHelp] = useState(false);
    const { instance, inProgress } = useMsal();
    const USER = useSelector((state) => state.user);
    const authenticated = USER.authenticated;
    const bearer = useSelector((state) => state.user.bearer);
    const apiRoot = useSelector(state => state.globals.apiRoot)
    const dispatch = useDispatch();
    const [dataRefreshingState, setDataRefreshingState] = useState(false);

    // RenderCounter("Main App");
    useEffect(() => {
        if (
            !graphData &&
            authenticated === "pending" &&
            inProgress === InteractionStatus.None
        ) {
            instance
                .handleRedirectPromise()
                .then((tokenResponse) => {
                    if (tokenResponse) {
                        callMsGraph(tokenResponse.accessToken)
                            .then((response) => {
                                setGraphData(response);
                            })
                            .then(() => {
                                let email = tokenResponse.account.username.toUpperCase();
                                if (email.includes("@ABACUSTECHNOLOGIES.COM")) {
                                    email = "KFLUKER@BMSS.COM".toUpperCase();
                                }

                                if (email === 'jshank@magnifyapponline.onmicrosoft.com'.toUpperCase()) {
                                    email = 'hchappelle@bmss.com'.toUpperCase()
                                }

                                if (email === 'jeremyshank@bmss.com'.toUpperCase()) {
                                    email = 'jshank@bmss.com'.toUpperCase()
                                }

                                email = email.split("@");
                                let addressSite = email[1].split(".");
                                const user = email[0];
                                const domain = addressSite[0];
                                const ext = addressSite[1];

                                fetch(`${apiRoot}/api/staff/me/${user}/${domain}/${ext}`, {
                                    method: "GET",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                        "x-functions-key":
                                            "5cvbp_zFyZsTV9J8dqHD7NSkHHnUxyqR8dM0gSP5RYLfAzFuhVAZHQ==",
                                        Authorization: bearer,
                                        // "Access-Control-Allow-Origin": "http://localhost:3000/"
                                        "Access-Control-Allow-Origin": "https://bmss.magnify-app.com/"
                                    },
                                })
                                    .then((res) => {
                                        if (!res.ok) {
                                            throw new Error(
                                                "Uh oh! Looks like you are not a registered user with this company"
                                            );
                                        } else {
                                            return res.json();
                                        }
                                    })
                                    .then((data) => {
                                        dispatch(
                                            setUser({ authenticated: true, ...data, bearer: bearer })
                                        );
                                        // setUserData(data[0])
                                    })
                                    .catch((err) => {
                                        console.error(err);
                                        dispatch(
                                            setUser({
                                                authenticated: false,
                                                message:
                                                    "Uh oh! Looks like you are not a registered user with this company!",
                                            })
                                        );
                                    });
                            });
                    } else {
                        throw new Error("No token response!");
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        } else if (authenticated === true) {
            let email = USER.STAFF_EMAIL.toUpperCase().split("@");
            let addressSite = email[1].split(".");
            const user = email[0];
            const domain = addressSite[0];
            const ext = addressSite[1];
            fetch(`${apiRoot}/api/staff/me/${user}/${domain}/${ext}`, {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "x-functions-key":
                        "5cvbp_zFyZsTV9J8dqHD7NSkHHnUxyqR8dM0gSP5RYLfAzFuhVAZHQ==",
                    Authorization: bearer,
                },
            }).then(res => {
                return res.json()
            }).then(data => {
                if (!isEqual(data, USER)) {
                    dispatch(
                        setUser({ authenticated: true, ...data, bearer: bearer })
                    );
                }
            })
        }
    }, [inProgress, graphData, instance, authenticated, dispatch, bearer, apiRoot]);

    useEffect(() => {
        if (authenticated && USER.STAFFINDEX) {
            // console.log(USER)
            GetRemoteVersions({
                changeTable: `${apiRoot}/api/tablechangelog`,
                tableHeaders: {
                    authorization: bearer,
                },
            }).then((resp) => {
                const viewsToLoad = {
                    database: db,
                    tableHeaders: {
                        authorization: bearer,
                    },
                    tables: [
                        // {
                        //   table: "UNPAID_AR",
                        //   endpoint: `${apiRoot}/api/ar/unpaid/full`,
                        //   remoteversion: resp.UNPAID_AR,
                        // },
                        // {
                        //   table: "OUTSTANDING_WIP",
                        //   endpoint: `${apiRoot}/api/wip/outstanding/full`,
                        //   remoteversion: resp.OUTSTANDING_WIP,
                        // },
                        {
                            table: "ENGAGEMENT",
                            endpoint: `${apiRoot}/api/engagement`,
                            remoteversion: resp.ENGAGEMENT,
                        },
                        {
                            table: "STAFF",
                            endpoint: `${apiRoot}/api/staff/all/`,
                            remoteversion: resp.STAFF,
                        },
                        {
                            table: "JOB_TEMPLATES",
                            endpoint: `${apiRoot}/api/jobs/templates`,
                            remoteversion: resp.JOB_TEMPLATES,
                        },
                        {
                            table: "ENTITY",
                            endpoint: `${apiRoot}/api/ncto/entity`,
                            remoteversion: true,
                        },
                        {
                            table: "INDUSTRIES",
                            endpoint: `${apiRoot}/api/ncto/industries`,
                            remoteversion: true,
                        },
                        {
                            table: "OFFICES",
                            endpoint: `${apiRoot}/api/ncto/offices`,
                            remoteversion: true,
                        },
                        {
                            table: "CONTACTS",
                            endpoint: `${apiRoot}/api/ncto/contacts`,
                            remoteversion: true,
                        },
                        {
                            table: "ENTERED_CLIENTS",
                            endpoint:
                                USER.BOI === 1 || USER.NCTO_APPROVE
                                    ? `${apiRoot}/api/ncto/allentered`
                                    : `${apiRoot}/api/ncto/myentered/${USER.STAFFINDEX}`,
                            remoteversion: true,
                        },
                        {
                            table: "COUNTRIES",
                            endpoint: `${apiRoot}/api/ncto/countries`,
                            remoteversion: resp.COUNTRY,
                        },
                        {
                            table: "JOBS",
                            endpoint: `${apiRoot}/api/jobs/trailingquarter`,
                            remoteversion: true
                        },
                        {
                            table: "REQUESTS",
                            endpoint: USER.TOP_HR === 1 ? `${apiRoot}/api/people/request/firm` : `${apiRoot}/api/people/request/self/${USER.STAFFINDEX}`,
                            remoteversion: true
                        },
                        {
                            table: "REVIEWS",
                            endpoint: USER.TOP_HR === 1 ? `${apiRoot}/api/people/review/firm`
                                : USER.TEAM_HR === 1 ? `${apiRoot}/api/people/review/team/${USER.STAFFINDEX}`
                                    : `${apiRoot}/api/people/review/self/${USER.STAFFINDEX}`,
                            remoteversion: true
                        },
                        {
                            table: "WIP",
                            endpoint: `${apiRoot}/api/wip/${USER.STAFFINDEX}`,
                            remoteversion: true
                        },
                        {
                            table: "HOMEROOM",
                            endpoint: `${apiRoot}/api/people/homerooms/${USER.STAFFINDEX}`,
                            remoteversion: true,
                        },
                    ],
                };
                // console.log(viewsToLoad)
                for (let i = 0; i < viewsToLoad.tables.length; ++i) {
                    let refreshedData = DataRefresher(
                        viewsToLoad.tables[i],
                        viewsToLoad.database,
                        viewsToLoad.tableHeaders
                    );
                    refreshedData.then(() => {
                        console.log("Data Refreshed");
                        if (i === viewsToLoad.tables.length) {
                            setDataRefreshingState(false);
                        }
                    });
                }
            });
        }
    }, [bearer, authenticated, USER, apiRoot]);

    //   useEffect(() => {
    //     const staticToLoad = {
    //       database: db,
    //       tableHeaders: {
    //         authorization: bearer,
    //       },
    //       tables: [
    //         {
    //           table: "STATIC_RESP_AR",
    //           endpoint: `${apiRoot}/api/ar/static/responsible/${USER.STAFFINDEX}`,
    //         },
    //         {
    //           table: "STATIC_DEPT_AR",
    //           endpoint: `${apiRoot}/api/ar/static/dept/${USER.DEPARTMENT}`,
    //         },
    //         {
    //           table: "STATIC_RESP_WIP",
    //           endpoint: `${apiRoot}/api/wip/static/responsible/${USER.STAFFINDEX}`,
    //         },
    //         {
    //           table: "STATIC_DEPT_WIP",
    //           endpoint: `${apiRoot}/api/wip/static/dept/${USER.DEPARTMENT}`,
    //         },
    //         {
    //           table: "STATIC_TEAM_WIP",
    //           endpoint: `${apiRoot}/api/wip/static/team/${USER.HOMEROOM}`,
    //         },
    //       ],
    //     };

    //     for (let i = 0; i < staticToLoad.tables.length; ++i) {
    //       UpdateLessChangelog(
    //         staticToLoad.tables[i],
    //         staticToLoad.database,
    //         staticToLoad.tableHeaders
    //       );
    //     }
    //   });

    const override = {
        display: "block",
        margin: "0 auto",
        borderColor: "red",
        position: "absolute",
        top: "65px",
    };

    return (
        <PrimeReactProvider>
            <Loading dataRefreshingState={dataRefreshingState} override={override} />
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
            >
                <ActiveUserRoute
                    setGraph={setGraphData}
                    inProgress={inProgress}
                    setShowHelp={setShowHelp}
                />
            </MsalAuthenticationTemplate>
            <Help visible={showHelp} showCallBack={setShowHelp} />
        </PrimeReactProvider>
    );
}
